import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar'; // El Navbar se mantiene cargado normalmente

// Lazy loading para los componentes
const Home = React.lazy(() => import('./components/Home'));
const Resultados = React.lazy(() => import('./components/Resultados')); // Carga diferida para Resultados
const Jobs = React.lazy(() => import('./components/Jobs')); // Si tienes otra ruta para 'Jobs', puedes añadirlo también

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="" style={{ paddingTop: '56px' }}>
        <Suspense fallback={<div className='container'>Cargando...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/trabajos/:terminoDeBusqueda" element={<Resultados />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
